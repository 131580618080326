import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"
import moment from "moment"
import RehypeReact from "rehype-react"


import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"

const Page = ({ data }) => {

  const renderAst = new RehypeReact({
        createElement: React.createElement,
        components: { 
                      "anchorlink": AnchorLink
                      ,"external": External
                      ,"assetsdirect": AssetsDirect
                      ,"datalist": DataList
                      ,"datalistrow": DataListRow
                    }
      }).Compiler

  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
    {
      pathname: '/topics/',
      crumbLabel: 'お知らせ'
    },
  ]

  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 
  const post = data.md
  const update = post.frontmatter.update 
                ? <span>
                    {lang==='ja'?"更新日: ":"updated on: "}
                    {lang==='ja'
                    ?moment(post.frontmatter.update).format(`YYYY年M月D日`)
                    :moment(post.frontmatter.update).format(`MMM. D, YYYY`)}
                  </span>
                : ''
  const draft = post.fields.draft 
                ? <span className="draft">{lang==='ja'?"下書き":"Draft"}</span>
                : ''

  const index = post.htmlAst.children.findIndex(
                  (child) => 
                  (child.type === 'element' 
                    && child.properties.dataLang === (lang==='ja'?'en':'ja') ) 
                  );
  if(index!==-1){
    post.htmlAst.children.splice(index, 1)
  }

  let title = ''
  let textonly = ''
  const l = lang === post.frontmatter.titllang ? lang : post.frontmatter.titllang 
  if(lang==="ja"){
    if(post.frontmatter.title){
      title = <h1 lang={l}>{post.frontmatter.title}</h1>
    }else{
      title = <h1 lang="en">{post.frontmatter.titleen}</h1>
      textonly = <p className="annotation">申し訳ございません。このページは日本語では書かれていません。</p>
      
    }
  }else{
    if(post.frontmatter.titleen){
      title = <h1 lang={l}>{post.frontmatter.titleen}</h1>
    }else{
      title = <h1 lang="ja">{post.frontmatter.title}</h1>
      textonly = <p className="annotation">Sorry. This article is written in Japanese text only.</p>
      
    }
  }
  return (
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={post.frontmatter.title} lang={lang} />

      <ContentLayout lang={lang}>
        
        {textonly}
        <div className="post-info">
          <span>{lang==='ja'?"公開日: ":"posted on: "}{lang==='ja'?post.frontmatter.date:post.frontmatter.dateen}</span>
          {update}
          {draft}
        </div>
        {title}
        <div className="topics-content" >
          {renderAst(post.htmlAst)}

        </div>
        

      </ContentLayout>
      
    </Layout>
  )
}

export default Page;

export const query = graphql`
  query ($slug: String!,$language: String!) {
    md: markdownRemark(fields: { slug: {eq: $slug }}) {
      html
      htmlAst
      frontmatter {
        date(formatString: "YYYY年M月D日")
        dateen:date(formatString: "MMM. D, YYYY")
        update
        title
        titleen
        titllang
      }
      fields {
        draft 
      }
    }

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }

  }
`